/* Modified sidenav styles for top positioning */
.sidenav,
.sidenavClosed {
  width: 100%; /* Span the full width of the viewport */
  height: 60px; /* Adjust this height for the closed state */
  min-height: 60px; /* Minimum height when sidenav is open */
  max-height: 160px; /* Maximum height when sidenav is open */
  transition: height 0.3s ease-in-out;
  background-color: rgb(10, 25, 41);
  padding-top: 1px;
  position: fixed; /* Keep fixed positioning */
  top: 0; /* Stick to the top of the viewport */
  left: 0; /* Align to the left of the viewport */
  z-index: 10000000000;
  overflow: hidden; /* Hide overflowing content */
  transition: opacity 0.5s ease, transform 0.5s ease; /* Add transitions for opacity and transform */
  opacity: 1; /* Full opacity when shown */
  transform: translateY(0); /* Normal position */
}

/* Adjust content margins for top sidenav */
.contentOpen,
.contentClosed {
  margin-top: 160px; /* Match the height of the open sidenav */
}

.sidenavHidden {
  display: none; /* Or you can use visibility: hidden; */
  opacity: 0; /* Fully transparent when hidden */
  transform: translateY(-100%); /* Slide up out of view */
  pointer-events: none; /* Prevent interaction when hidden */
}

/* Modify .sideitem for horizontal layout */
.sideitem {
  display: inline-flex; /* Change to inline-flex for horizontal alignment */
  align-items: center;
  padding: 10px 20px;
  cursor: pointer;
  color: #b2bac2;
  text-decoration: none;
}

.linkText {
  padding-left: 16px;
  text-align: center; /* Center text horizontally */
}

/* Adjust positioning of menu and logout buttons */
.menuBtn,
.logoutBtn {
  background-color: transparent;
  position: static; /* Remove absolute positioning */
  margin-right: 20px; /* Add right margin for spacing */
  border: transparent;
  padding: 0;
  cursor: "pointer";
}

/* Style adjustments for menu and logout SVG icons */
.menuBtn svg,
.logoutBtn svg {
  cursor: pointer;
  color: #b2bac2;
  text-decoration: none;
}
