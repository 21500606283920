.edit-button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 4px;
  border: none;
  background-color: transparent;
  color: #999;
  cursor: pointer;
  font-size: 14px;
}

.edit-button:hover {
  color: #555;
}

.edit-button::after {
  content: "\270E"; /* Pencil icon Unicode */
  margin-left: 4px;
}

.settings-button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 4px;
  border: none;
  background-color: transparent;
  color: #ccc;
  cursor: pointer;
  font-size: 14px;
}

.delete-button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 4px;
  border: none;
  background-color: transparent;
  color: #ccc;
  cursor: pointer;
  font-size: 14px;
}

.delete-button:hover {
  color: #f01010;
}

.delete-button::after {
  content: "\2715"; /* Pencil icon Unicode */
  margin-left: 4px;
}

.form-popup {
  width: 100%;
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
}

.form-popup input[type="text"] {
  width: 200px;
  padding: 10px;
  margin-right: 10px;
}

.form-popup button {
  padding: 10px 20px;
  background-color: #ccc;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.form-popup button[type="submit"] {
  background-color: #4c56af;
  color: #fff;
}

.form-popup button[type="submit"]:hover {
  background-color: #45a049;
}

.form-response {
  background-color: #1c1b1b;
  padding: 10px;
  border-radius: 4px;
  margin-top: 10px;
  animation: fadeOut 3s ease-in-out;
}

.modal-overlay {
  position: fixed;
  top: 5%;
  left: 5%;
  right: 5%;
  bottom: 5%;
  background-color: #1f1f1f;
  z-index: 1005;
}

.modal-content {
  position: absolute;
  top: 14px;
  left: 14px;
  right: 14px;
  bottom: 14px;
  border: 1px solid #ccc;
  background-color: #1f1f1f;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  border-radius: 4px;
  outline: none;
}

.close-icon {
  position: absolute;
  top: 1px;
  color: rgba(255, 0, 140, 0.858);
  right: 8px;
  cursor: pointer;
  font-size: 40px;
  font-weight: bold;
  z-index: 10000;
}

.table-container {
  width: 100%;
  margin-bottom: 20px;
  display: flex;
  padding-right: 20px;
  justify-content: center;
}

/* Media query for decreasing graph size on smaller screens */
@media screen and (max-width: 768px) {
  .graph-container {
    height: 10vh; /* Adjust the height for smaller screens */
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  90% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

/* EdgeSettings */

.settings-table-container table {
  width: 100%;
  margin-bottom: 20px;
  padding-top: 20px;
  display: flex;
  text-align: left;
  justify-content: center;
}

.settings {
  position: absolute;
  top: 0%;
  left: 0%;
  right: 0%;
  bottom: 0%;
  background-color: #1f1f1f;
  color: white; /* Optionally set text color to contrast with the background */
  /* Add any other styling you need */
}

.settings-section-header {
  text-align: left;
  padding-left: 20px; /* Add padding to the left */
  padding-right: 20px; /* Add padding to the right */
  text-align: center; /* Align text to the left */
  color: white; /* Optionally set text color to contrast with the background */
  /* Add any other styling you need */
}

.settings-content {
  left: 20px;
  right: 20px;
  color: white; /* Optionally set text color to contrast with the background */
  /* Add any other styling you need */
}

.config-form-container {
  background-color: #555555;
  padding: 20px;
  position: relative;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  display: flex; /* This will center the form fields container */
  flex-direction: column; /* Stack the children vertically */
  align-items: center; /* Center the items horizontally */
}

.form-field {
  display: flex;
  align-items: center; /* Align the label and input vertically */
  margin-bottom: 10px;
}

.form-field label {
  text-align: right; /* Align the text to the right for aesthetics */
  margin-right: 10px; /* Space between the label and the input */
}

.form-field input {
  /* Remove the flex-grow property to only use the length necessary for input */
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.form-actions {
  margin-top: 20px;
  display: flex;
  justify-content: flex-end;
}

.form-actions button {
  margin-left: 10px;
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.form-actions button[type="submit"] {
  background-color: #5cb85c;
  color: white;
}

.form-actions button[type="button"] {
  background-color: #f0f0f0;
}
