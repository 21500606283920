.mapbox-gl-popup-content {
  position: absolute;
  top: 10px;
  left: 10px;
  background-color: red;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  z-index: 1;
  color: black;
}

.my-popup {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(0, 0, 0, 0.8);
  color: #080808;
  padding: 20px;
  border-radius: 8px;
}

.my-popup {
  text-align: center;
}

.my-popup h3 {
  margin-top: 0;
}

.my-popup button {
  background-color: #070707;
  color: #000;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.my-popup button:hover {
  background-color: #080808;
}

.hover-info {
  position: absolute;
  z-index: 1;
  background-color: rgb(12, 11, 11);
  padding: 10px;
  border-radius: 4px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}

.hover-info h3 {
  font-size: 16px;
  margin: 0;
}

.hover-info p {
  margin: 5px 0;
}

.hover-info button {
  padding: 5px 10px;
  background-color: #007bff;
  color: rgb(39, 5, 5);
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.hover-info button:hover {
  background-color: #0056b3;
}

.popup-box {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(0, 0, 0, 0.8);
  color: #a40505;
  padding: 20px;
  border-radius: 8px;
}

.popup-box-content {
  text-align: center;
}

.popup-box h3 {
  margin-top: 0;
}

.popup-box button {
  background-color: #fff;
  color: #000;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.popup-box button:hover {
  background-color: #784040;
}

.mapboxgl-popup {
  max-width: 300px;
  padding: 10px;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.mapboxgl-popup h3 {
  color: #f81010; /* Change the color of <h3> elements within the popup */
}

.mapboxgl-popup p {
  color: blue; /* Change the color of <p> elements within the popup */
}
