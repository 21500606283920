/* Datepicker Wrapper */
.datepicker-wrapper {
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-bottom: 2px;
  height: 10vh;
  position: relative; /* Add position relative to the wrapper */
}

.react-datepicker__input-container {
  width: inherit;
  color: #f4f7fa;
  font-size: 1.2rem;
  font-weight: 500;
}

.react-datepicker__header {
  text-align: center;
  background-color: #3e82cf;
  border-bottom: 0px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  padding-top: 8px;
  position: relative;
}

.react-datepicker__today-button {
  background: #3e82cf;
  border-top: 0px;
  cursor: pointer;
  text-align: center;
  font-weight: bold;
  padding: 5px 0;
  clear: left;
}
