/* Body and App Styles */
body {
  margin: 0;
  padding: 0;
  display: flex;
  padding-top: 60px;
  flex-direction: column;
  justify-content: center;

  min-height: 10vh; /* Ensure full viewport height */
  background-color: #1f1f1f;
  color: #f1f1f1;
  text-align: center;
  z-index: 1000000; /* Increase the z-index value */
}

/* Table Styles */
table {
  border-collapse: collapse;
}

/* App Container and Content Wrapper Styles */
.app-container {
  display: flex;
}

th,
td {
  border: none;
  text-align: center;
  padding: 5px;
  width: 10%;
}

main {
  padding: 0px;
  flex: 1;
  z-index: 1;
  position: relative;
}
