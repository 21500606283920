.Header {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0;
  padding: 0;
}

.edge-device-select {
  margin: 100;
  padding: 100;
  width: 95%;
  height: 50vh;
  margin-bottom: 50px;
}

@media screen and (max-width: 480px) {
  .graph-container,
  .line-chart {
    height: 15vh; /* Adjust the height for very small screens */
  }
}

.flex-container {
  display: flex;
  flex-direction: column;
  align-items: center; /* Center items horizontally in the column */
  width: 100%; /* Take full width */
}

.Header,
.graph-container,
.line-chart,
.responsive-container,
.table-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.graph-container {
  margin: 0;
  padding: 0;
  width: 99%;
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically if needed */

  height: 20vh;
  margin-bottom: 50px;
}

.line-chart {
  margin: 0;
  padding: 0;
}

.table-container {
  width: 100%;
  margin-bottom: 20px;
  padding: 0px;

  justify-content: center;
}

h1,
h2 {
  margin-top: 20px;
  margin-bottom: 10px;
}

/* Mapbox Modal Overlay */

.modal-overlay {
  position: fixed;
  top: 5%;
  left: 5%;
  right: 5%;
  bottom: 5%;
  background-color: rgba(255, 255, 255, 0.75);
  z-index: 1000;
}

.modal-content {
  position: absolute;
  top: 14px;
  left: 14px;
  right: 14px;
  bottom: 14px;
  border: 1px solid #ccc;
  background: #fff;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  border-radius: 4px;
  outline: none;
}

.close-icon {
  position: absolute;
  top: 1px;
  color: rgba(255, 0, 140, 0.858);
  right: 8px;
  cursor: pointer;
  font-size: 40px;
  font-weight: bold;
  z-index: 10000;
}
